<template>
    <div class="account-content">
<!--        <div class="del-wrapper">-->
<!--            <div class="del-btn" @click="deletePro">-->
<!--                <i class="el-icon-delete"></i>删除-->
<!--            </div>-->
<!--            <span class="del-number">已选{{chooseNumber}}个</span>-->
<!--        </div>-->
        <el-table :data="shortTable" style="width: 100%; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#ECEBFF',height:'50px'}"
                  :cell-style="{fontSize: '12px',color: '#333',}">
<!--            <el-table-column type="selection" width="55"></el-table-column>-->
            <el-table-column label="序号" align="left"  width="200">
                <template slot-scope="scope">
                    {{scope.$index+1}}
                </template>
            </el-table-column>
            <el-table-column prop="platform_type" label="平台" align="left">
                <template slot-scope="scope">
                    <div class="account-number">
                        <img :src="scope.row.platform_type===1?require('../../assets/img/image/douyin.png'):require('../../assets/img/image/baijiahao.png')">
                        <span class="scope-title">{{scope.row.platform_type===1?'抖音号':'百家号'}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="账号">
                <template slot-scope="scope">
                    <div class="account-number" v-if="scope.row.platform_type===1" @click="gotoData(scope.row)">
                        <img :src="scope.row.avatar" width="30" height="30">
                        <span class="scope-title">{{scope.row.nickname}}</span>
                    </div>
                    <div class="account-number" v-else @click="gotoData(scope.row)">
                        <img :src="scope.row.avatar" width="30" height="30">
                        <span class="scope-title">{{scope.row.nickname}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="state" label="审核状态" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.platform_type===1">
                        <span :class="scope.row.audit_status==0?'':scope.row.audit_status==1?'under-passed':scope.row.audit_status==2?'under-err':'under-err'">
                            {{scope.row.audit_status==0?'审核中':scope.row.audit_status==1?'审核通过':scope.row.audit_status==2?'审核不通过':'驳回审核'}}
                        </span>
                    </div>
                    <div class="under-passed" v-else>
                        <span>审核通过</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="160">
                <template slot-scope="scope">
                    <div class="operation">
                        <el-button @click="getTop(scope.row.id)" type="text" class="topping">
                            <span v-show="scope.row.top === 0">置顶</span>
                        </el-button>
                        <el-button @click="unPinkTop(scope.row.id)" type="text" class="topping">
                            <span v-show="scope.row.top === 1">取消置顶</span>
                        </el-button>
                        <el-button @click="deleteRow(scope.row)" type="text" class="del">
                            <span>删除</span>
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center" style="padding-bottom: 10px;"
                :current-page="studentPages.currentPageNum"
                :page-size="studentPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="studentPages.total"
                v-show="shortTable.length > 0"
                @current-change="studentCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "ShortVideoModule",
        props: ['isFresh'],
        data() {
            return {
                shortTable: [],
                currentSort: 0,
                platform_type: '',//平台类型
                // sortList:['抖音'],
                multipleSelection: [],
                chooseNumber: 0,
                //分页
                studentPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted() {
            this.getAccountList()
        },
        //扫码成功后刷新列表
        watch: {
            isFresh(codeList) {
                if (codeList === true) {
                    this.getAccountList()
                }
            }
        },
        methods: {
            // chooseSort(sortIndex) {
            //     this.currentSort = sortIndex;
            //     this.$emit('shortVideoTab',this.currentSort)
            // },
            // handleSelectionChange(val) {
            //     this.multipleSelection = val;
            //     this.chooseNumber = val.length
            // },
            // deletePro(){
            //     console.log(1111)
            // },
            // 获取列表
            getAccountList() {
                let param = {
                    page: this.studentPages.currentPageNum,
                    pageSize: this.studentPages.eachPageNum,
                };
                this.$httpStudent.axiosGetBy(this.$api.get_account, param, res => {
                    if (res.code === 200) {
                        this.shortTable = res.data.data;
                        this.studentPages.total = res.data.total;
                    } else if (res.code === 204) {
                        this.$message.error(res.message);
                        this.$router.push('/');
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            // 置顶
            getTop(id) {
                let param = {
                    id: id,
                    need_top: 1
                };
                this.$httpStudent.axiosPost(this.$api.account_top, param, (res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: "success",
                            message: '置顶成功',
                            duration: 1000,
                        });
                        this.getAccountList()
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 取消置顶
            unPinkTop(id) {
                let param = {
                    id: id,
                    need_top: 0
                };
                this.$httpStudent.axiosPost(this.$api.account_top, param, (res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: "success",
                            message: '取消置顶成功',
                            duration: 1000,
                        });
                        this.getAccountList()
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            gotoData(row) {
                if(Number(row.auth) === 0){
                    this.$message.warning('授权失败，该账号被其他人绑定不能查看')
                    return
                }
                if (row.platform_type === 1) {
                    if (row.audit_status === 1) {
                        this.$router.push({
                            path: '/student/data/details',
                            query: {
                                id: row.id,
                                platform: 1,
                                type: 1
                            }
                        })
                    } else {
                        this.$message({
                            type: "warning",
                            message: '账号还未审核成功',
                            duration: 1000,
                        });
                    }
                } else {
                    let param = {
                        type:2,
                        platform_type:7,
                        id:row.id,
                    };
                    this.$httpStudent.axiosGetBy(this.$api.get_account, param, res => {
                        if (res.code === 200) {
                            window.open(res.data.url)
                        } else if (res.code === 204) {
                            this.$message.error(res.message);
                        }
                    }, (err) => {
                        console.log(err);
                    })

                }
            },
            // 删除
            deleteRow(row) {
                this.$confirm('是否删除该数据，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    let param = {
                        id: row.id,
                        type: row.type,
                        platform_type: row.platform_type,
                    }
                    this.$httpStudent.axiosPost(this.$api.account_delete, param, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message);
                            this.getAccountList()
                        } else {
                            this.$message.warning(res.message)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
            studentCurrentChange(val) {
                this.studentPages.currentPageNum = val;
                this.getAccountList()
            },
        }
    }
</script>

<style scoped lang="scss">
    .account-content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .del-wrapper {
            margin-bottom: 20px;
            display: flex;

            .del-btn {
                cursor: pointer;
                transition: all .3s;

                i {
                    margin-right: 6px;
                }

                &:hover {
                    color: #5864e4;
                }
            }

            .del-number {
                margin-left: 20px;
            }
        }

        .account-number {
            display: flex;
            align-items: center;

            img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }

            .scope-title {
                max-width: 128px;
                margin-left: 10px;
                color: #333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                cursor: pointer;
                font-size: 14px;
            }
        }

        .down {
            & i {
                font-size: 14px;
                color: #42D7AB;
                -ms-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                padding-right: 10px;
                display: inline-block;
                transform: rotate(180deg);
            }
        }

        .up {
            & i {
                font-size: 14px;
                color: #FF0000;
                padding-left: 10px;
            }
        }

        .operation {
            .topping {
                span {
                    color: #4659f2;

                    &:hover {
                        color: #463bdc;
                    }
                }
            }

            .del {
                span {
                    color: #f56c6c;

                    &:hover {
                        color: #ff0000;
                    }
                }
            }
        }

        .under-passed {
            color: #1BB285;
        }

        .under-err {
            color: #f56c6c;
        }

        .script-content-list {
            .script-content-tab {
                ::v-deep .el-tabs__item {
                    height: 45px;
                    line-height: 45px;
                    font-size: 16px;
                    font-weight: 400;
                    border: none;
                    color: #16113F;
                }

                ::v-deep .el-tabs__active-bar {
                    background: #9ACC5B;
                }

                ::v-deep .el-tabs__active-bar:before {
                    position: absolute;
                    top: -13px;
                    left: 40%;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: dashed dashed solid dashed;
                    border-width: 7px;
                    border-color: transparent transparent #9ACC5B transparent;
                }

                /*::v-deep.el-tabs__item{*/
                /*    padding: 0;*/
                /*    margin-right:20px;*/
                /*    box-sizing: border-box;*/
                /*}*/
            }

            .dy-main {
                display: flex;
                flex-direction: column;
                height: calc(100vh - 286px);

                ::v-deep .el-table {
                    display: flex;
                    flex-direction: column;
                }

                ::v-deep .el-table__footer-wrapper, ::v-deep .el-table__header-wrapper {
                    overflow: initial;
                }

                ::v-deep .el-table__body-wrapper {
                    overflow: auto;
                }
            }
        }
    }

</style>